.Zoom_image {
    .image {
        @apply w-full sm:h-[700px] h-[350px] object-cover rounded-[10px];

        div {
            @apply sm:h-[700px] h-[350px] w-full object-cover;

            img {
                @apply sm:h-[700px] h-[350px] w-full object-cover;
            }
        }
    }
}